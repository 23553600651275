/* ready */
$(function () {
  'use strict';

  var $slide = $(".p-top-mv__slide").slick({
    arrows: false,
    autoplay: "auto",
    fade: true,
    dots: true
  });
  $slide.find(".slick-slide").eq(0).addClass("slide-animation");
  
  $(".p-top-news__slider").slick({
    arrows: false,
    dots: false
  });
  
  $('.p-top-news__head__nav').slick({
    asNavFor:'.p-top-news__slider',
    focusOnSelect: true,
    arrows: false,
    draggable: false,
    slidesToShow:5,
    infinite:false
  });

  $(".p-top-news__head__nav li").click(function(){
    $(".p-top-news__head__nav li").removeClass("is-active");
    $(this).addClass("is-active");
  });
  
  $('.p-top-pickup ul').slick({
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<div class="slide-arrow prev-arrow"><img src="/images/common/ico-arrow02.svg" alt=""></div>',
    nextArrow: '<div class="slide-arrow next-arrow"><img src="/images/common/ico-arrow02.svg" alt=""></div>',
    responsive: [ {
      breakpoint: 768,
      settings: 'unslick'
    } ]
  });
  
  //リサイズした時に実行
  $(window).on('resize orientationchange', function() {
    $('.p-top-pickup ul').slick('resize');
  });
});