/* ready */
$(function () {
  'use strict';

  //メガメニュー
  $(".l-header__nav__list li").hover(function () {
    $(this).find('.l-header__nav__list__mega').stop().slideDown();
  }, function () {
    $(this).find('.l-header__nav__list__mega').stop().slideUp();
  });

  $(document).ready(function () {
    $(".l-header__nav__list__mega").css("top", $(".l-main").offset().top + "px");
  });
  $(window).resize(function () {
    $(".l-header__nav__list__mega").css("top", $(".l-main").offset().top + "px");
  });

  //ページ内リンク
  $('a[href^="#"]').on('click', function () {
    var speed = 300;
    var href = $(this).attr('href');
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  //スマホメニュー
  $(".l-footernav__menu").click(function () {
    if ($(".l-footernav__menu__nav").hasClass("is-open")) {
      $(".l-footernav__menu__nav").removeClass("is-open");
      $(".l-footernav__menu").removeClass("is-active");
      $("body").removeClass("is-menu");
    } else {
      $(".l-footernav__menu__nav").addClass("is-open");
      $(".l-footernav__menu").addClass("is-active");
      $("body").addClass("is-menu");
    }
  });
  
  $(".l-footernav__menu__nav span").click(function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });

  //ページトップボタン
  $(".l-pagetop").click(function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
  });

  //追従ナビ
  var isScrolling = 0;
  var timeoutId;

  $(window).scroll(function () {
    if ($(this).scrollTop() > 600) {
      $(".l-pagetop").addClass("is-active");
      $(".l-footernav").addClass("is-active");
    } else {
      $(".l-pagetop").removeClass("is-active");
      $(".l-footernav").removeClass("is-active");
    }

    if (window.innerWidth < 768) {
      isScrolling = 1;
      $(".l-footernav").removeClass("is-active");
      $("#ulccwidparent").removeClass("is-active");

      // スクロールを停止して500ms後に終了とする
      clearTimeout(timeoutId);

      timeoutId = setTimeout(function () {
        isScrolling = 0;
        $(".l-footernav").addClass("is-active");
        $("#ulccwidparent").addClass("is-active");
      }, 500);
    }
  });

  //関連メニュー
  $(".p-page-menu__close").click(function () {
    $(".p-page-menu").toggleClass("is-open");
    $(".p-page-menu__inner").slideToggle(200, 'swing');
  });

  //ニュースのSPスライダー
  $(".p-infomation__pickup__slider").slick({
    autoplay: "auto",
    prevArrow: '<img src="/images/common/ico-arrow07.svg" alt="">',
    nextArrow: '<img src="/images/common/ico-arrow07.svg" alt="">',
    dots: false
  });

  $('.p-page-anchor__btn').click(function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });
  $('.p-page-anchor02__btn').click(function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });

  $('.p-faq dt').click(function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });
  $('.c-list--qa dt').click(function () {
    $(this).toggleClass('is-open').next().stop().slideToggle();
  });

  $('.is-matchheight').matchHeight();

  $("a").each(function () {
    var replace = null;
    var title = $('input[name="title"]').val();
    var type = $('input[name="type"]').val();

    if ($(".lang_en")[0]) {
      replace = $(this).attr('href').replace(/form\/en\/index.php/g, 'form/en/index.php?ref=' + location.href);
    } else {
      replace = $(this).attr('href').replace(/com_form.php/g, 'com_form.php?type=' + type + '&title=' + title + '&ref=' + location.href);
    }
    $(this).attr('href', replace);
  });

  //動画
  $(".c-movie").click(function () {
    var url = $(this).attr('href');
    $("body").prepend('<div class="c-modal"><div class="c-modal__bg"></div><div class="c-modal__content"><div class="c-modal__content__inner"><iframe src="' + url + '"></iframe></div><div class="c-modal__close"><span>×</span>閉じる</div></div></div>');
    $(".c-modal").fadeIn();
    $('.c-modal__close').on('click', function () {
      $('.c-modal').fadeOut().queue(function () {
        this.remove();
      });
      return false;
    });
    return false;
  })
});